import { Breakpoint } from "@mui/material";

export interface ILoginPayload {
    username: string;
    password: string;
}
export interface ISelectInput {
    label: string;
    value: string | number;
}
export enum IDrawersNames {
    ALL = "ALL",
    OPEN_DIALOG = "OPEN_DIALOG",
    CLOSE_DIALOG = "CLOSE_DIALOG",
    NEWSPAPER_DRAWER = "NEWSPAPER_DRAWER",
    SIDEBAR = "SIDEBAR",
}

type TAnchor = "left" | "right" | "top" | "bottom";

export interface IDrawerProps {
    Name: IDrawersNames;
    DrawerData: any;
    Anchor?: TAnchor;
    maxWidth?: string;
    onClose?: any;
}
export interface IPagination {
    totalCount: number;
    page: number;
}

export interface IDefaultQuery {
    page?: number;
    search?: string;
}

export enum RoutesEnum {
    login = "/login",
    home = "/",
    profile = "/profile",
    folder = "/folder",
    fav = "/fav",
    create = "/create",
}
export enum HashtagDetailsRoute {
    top_influencers = "top-influencers",
    top_posts = "top-posts",
    profile_analysis = "profile-analysis",
    hashtag_reports = "hashtag-reports",
    media_wall = "media-wall",
}
export type IBreadcrumb = {
    title: string;
    path: string;
    active?: boolean;
    disabled?: boolean;
};

export interface IProduct {
    name: string;
    quantity?: number | null;
    price?: number;
    amount: number;
    [title: string]: any;
}
export enum ProductEnum {
    name = "name",
    quantity = "quantity",
    price = "price",
    amount = "amount",
}

export interface IInvoiceData {
    customer: ICustomer;
    products: IProduct[];
}
export interface ICustomer {
    name?: string;
    phone?: string;
    email?: string;
}
