import { Switch } from "react-router";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import { RoutesEnum } from "./utils/types";
import CreateInvoice from "./views/CreateInvoice";
import FavouritePage from "./views/FavouritePage";
import FolderPage from "./views/FolderPage";
import Homepage from "./views/Homepage";
import Profile from "./views/Profile";

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path={RoutesEnum.login}>
                        <Homepage />
                    </Route>
                    <Route exact path={RoutesEnum.home}>
                        <Homepage />
                    </Route>
                    <Route exact path={RoutesEnum.profile}>
                        <Profile />
                    </Route>
                    <Route exact path={RoutesEnum.create}>
                        <CreateInvoice />
                    </Route>
                    <Route exact path={RoutesEnum.fav}>
                        <FavouritePage />
                    </Route>
                    <Route exact path={RoutesEnum.folder}>
                        <FolderPage />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
