import AddIcon from "@mui/icons-material/Add";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { parseParam } from "../utils/functions";
import { RoutesEnum } from "../utils/types";
export default function LabelBottomNavigation() {
    const history = useHistory();
    const params = parseParam(history.location.pathname);
    const [value, setValue] = React.useState(`/${params[0]}`);
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
        history.push(newValue);
    };

    return (
        <BottomNavigation className="bottom-nav" value={value} onChange={handleChange}>
            <BottomNavigationAction label="Home" value={RoutesEnum.home} icon={<HomeIcon />} />
            <BottomNavigationAction label="Favorites" value={RoutesEnum.fav} icon={<FavoriteIcon />} />
            <div className="add-icon bottom-nav-icon">
                <IconButton onClick={() => handleChange(null, RoutesEnum.create)} className="bottom-nav add-icon">
                    <AddIcon />
                </IconButton>
            </div>
            <BottomNavigationAction label="Folder" value={RoutesEnum.folder} icon={<FolderIcon />} />
            <BottomNavigationAction label="Profile" value={RoutesEnum.profile} icon={<PersonIcon />} />
        </BottomNavigation>
    );
}
