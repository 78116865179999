import { CloseOutlined } from "@mui/icons-material";
import * as htmlToImage from "html-to-image";
import moment from "moment";
import { useState } from "react";
import { IProfileData } from "../redux/action";
import { getCookie, getCookieObj } from "../utils/cookie";
import { removeInvoice } from "../utils/functions";
import { InvoiceWrapper } from "../utils/rxSubjects";
import { IInvoiceData } from "../utils/types";

const InvoiceWrapperPage = (props: any) => {
    const profile: IProfileData = getCookieObj("profile");
    const [invoiceData, setInvoiceData] = useState<IInvoiceData>();
    const [image, setImage] = useState<any>();
    InvoiceWrapper.subscribe((data) => {
        setInvoiceData(data?.data);
        let node: any = document.getElementById("invoice-wrapper");
        node.style.display = data.flag ? "block" : "none";
    });
    const handleClose = () => {
        removeInvoice();
    };
    const handleShare = () => {
        let elemNode = document.getElementById("invoice-pdf");
        console.log("elemNode", elemNode);
        htmlToImage
            .toPng<any>(elemNode)
            .then(function (dataUrl) {
                shareItem(dataUrl);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    const handleSave = () => {
        let elemNode = document.getElementById("invoice-pdf");
        htmlToImage
            .toPng<any>(elemNode)
            .then(async (dataUrl) => {
                const link = document.createElement("a");
                link.download = `medebill-invoice.png`;
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    const shareItem = async (dataUrl: any) => {
        console.log(invoiceData);
        return;
        try {
            const blob = await (await fetch(dataUrl)).blob();
            const file = new File([blob], "fileName.png", { type: blob.type });
            console.log("file", file);
            await navigator?.share({
                files: [file],
                title: "Medbill Invoice",
            });
        } catch (error) {
            alert(error);
            console.log("error", error);
        }
    };

    return (
        <div id="invoice-wrapper" className="hide-scrollbar" style={{ display: "none" }}>
            <div className="py-2 container">
                <div className="d-flex justify-content-between">
                    <h4 className="text-muted">Invoice</h4>
                    <div className="d-flex">
                        <div onClick={handleSave} className="d-flex pointer button orange line bold btn-sm mx-2">
                            Save
                        </div>
                        <div onClick={handleShare} className="d-flex pointer button orange line bold btn-sm mx-2">
                            Share
                        </div>
                        <CloseOutlined fontSize="large" onClick={handleClose} className="p-0" />
                    </div>
                </div>
                <hr className="mb-0" />
                <div id="invoice-pdf" className="px-2 bg-white">
                    <div className="">
                        <br />
                        <div className="invoice-header d-flex justify-content-between w-100">
                            <div className="header-left w-auto">
                                <img
                                    src={profile?.businessLogo || "/logo192.png"}
                                    height={"100px"}
                                    width="auto"
                                    className="business-logo"
                                    alt="business logo"
                                />
                            </div>
                            <div className="invoice-detail w-auto text-left">
                                <div className="d-flex">
                                    <span className="text-muted">invoice # : </span>
                                    <span className="pl-2 text-muted bold">INV-{parseInt(`${Math.random() * 1000}`)}</span>
                                </div>
                                <div className="d-flex">
                                    <span className="text-muted">invoice date : </span>
                                    <span className="pl-2 text-muted bold">{moment().format("Do MMM, yyyy")}</span>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="d-flex justify-content-between w-100">
                            <div className="business-details mb-3">
                                <h4 className="business_name bold fntSz14">{profile?.businessName}</h4>
                                <p className="text-muted m-0 business_owner_name fntSz12">{profile?.ownerName}</p>
                                <p className="text-muted m-0 business_owner_name fntSz12">{profile?.phone ? `+91 ${profile?.phone}` : ""}</p>
                                <p className="text-muted m-0 business_owner_name fntSz12">{profile?.email}</p>

                                <p className="text-muted m-0 business_address fntSz12">
                                    {profile?.businessAddress?.addressLine1 ? `${profile?.businessAddress?.addressLine1}, ` : ""}

                                    {profile?.businessAddress?.addressLine2 ? `${profile?.businessAddress?.addressLine2}, ` : ""}
                                    {profile?.businessAddress?.city ? `${profile?.businessAddress?.city}, ` : ""}
                                    {profile?.businessAddress?.state}
                                </p>
                                <p className="text-muted m-0 business_address fntSz12">{profile?.businessAddress?.postalCode}</p>
                                <p className="text-muted m-0 business_address fntSz12">{profile?.businessAddress?.country || "India"}</p>
                            </div>
                            <div className="customer-details mb-3">
                                <h4 className="business_name bold fntSz14">Bill To</h4>
                                <p className="text-muted m-0 business_owner_name fntSz12">{invoiceData?.customer?.name}</p>
                                <p className="text-muted m-0 business_address fntSz12">
                                    {invoiceData?.customer?.phone ? `+91 ${invoiceData?.customer?.phone}` : ""}
                                </p>
                                <p className="text-muted m-0 business_address fntSz12">{invoiceData?.customer?.email}</p>
                            </div>
                        </div>
                        <br />
                        <div className="invoice-table ">
                            <div className="table-head col-12 row px-0 py-2 fntSz14 m-0 text-white bg-dark ">
                                <div className="col-5">Item Description</div>
                                <div className="col-2 text-right">Qty</div>
                                <div className="col-2 text-right">Rate</div>
                                <div className="col-3 text-right">Amount</div>
                            </div>
                            <div className="table-body">
                                {invoiceData?.products?.map((item, index) => (
                                    <div key={index} className="table-row col-12 row px-0 py-1 fntSz13 m-0">
                                        <div className="col-5">{item.name}</div>
                                        <div className="col-2 text-right">{item.quantity || "-"}</div>
                                        <div className="col-2 text-right">{item.price ? `₹${item.price}` : "-"}</div>
                                        <div className="col-3 text-right">₹{item.amount}</div>
                                    </div>
                                ))}
                                <br />
                                <h5 className="bold text-right pr-2 underline">
                                    Total : ₹
                                    {invoiceData?.products.reduce((sum, item) => {
                                        return sum + Number(item.amount);
                                    }, 0)}
                                </h5>
                            </div>
                        </div>
                        <br />
                        <div className="signature-cont">
                            <img
                                hidden={!profile?.signature}
                                src={profile?.signature || ""}
                                height={"auto"}
                                width="auto"
                                className="business-logo"
                                alt="business logo"
                            />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceWrapperPage;
