import { Dispatch } from "redux";
import { getCookieObj, setCookie, setCookieObj } from "../utils/cookie";
import { sleep } from "../utils/functions";
import { getWithToken, postApi } from "../utils/request";
import * as actionTypes from "../utils/types";

export const UPDATE_UI_LANGUAGE = "UPDATE_UI_LANGUAGE";
/**
 * @description method to update UI language over the project
 * @author Jagan
 * @date 29-10-2021
 * @param lang: string
 */
export function changeUiLanguage(lang: string) {
    return async function (dispatch: Dispatch<UiLanguageChangeAction>) {
        setCookie("lang", lang);
        dispatch({
            type: UPDATE_UI_LANGUAGE,
            payload: lang,
        });
    };
}
export interface UiLanguageChangeAction {
    type: typeof UPDATE_UI_LANGUAGE;
    payload: string;
}

export const loginAction = (payload: actionTypes.ILoginPayload) => {
    return async function () {
        try {
            const response = await postApi("/login", payload);
            if (response.status === 200) {
                const result = response.data;
                setCookie("token", `Bearer ${result.access}`);
                setCookie("refreshToken", `${result.refresh}`);
            } else {
                throw response;
            }
            return response;
        } catch (error: any) {
            console.log("error", error);
            throw error;
        }
    };
};

export const PAGINATION = "PAGINATION";

export interface IPaginationAction {
    type: typeof PAGINATION;
    payload: actionTypes.IPagination;
}
export const COUNTRIES_LIST = "COUNTRIES_LIST";
export interface ICountry {
    label: string;
    value: string;
}
export interface IGetCountryListAction {
    type: typeof COUNTRIES_LIST;
    payload: ICountry[];
}

export const getCountryList = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await getWithToken(`/country`);
            if (response?.data?.data) {
                // const countriesList = response.data.data?.map((item) => ({ label: item.name, value: item.id }));
                // dispatch({
                //     type: COUNTRIES_LIST,
                //     payload: countriesList || [],
                // });
                // return countriesList;
            } else {
                return [];
            }
        } catch (error) {
            console.error("error", error);
            throw error;
        }
    };
};

export interface IAddress {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: number;
}

export interface IProfileData {
    businessName?: string;
    businessLogo?: string;
    ownerName?: string;
    ownerPhoto?: string;
    phone?: string;
    email?: string;
    signature?: string;
    businessAddress?: IAddress;
}
export interface IGetProfileDataAction {
    type: typeof GET_PROFILE_DATA;
    payload: IProfileData;
}

export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const getProfileDataAction = () => {
    return async (dispatch: Dispatch) => {
        try {
            const result = getCookieObj("profile");
            await sleep(200)
            dispatch({
                type: GET_PROFILE_DATA,
                payload: result,
            });
        } catch (error) {
            console.error("error", error);
            throw error;
        }
    };
};

export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";
export const updateProfileDataAction = (payload: IProfileData) => {
    return async (dispatch: Dispatch) => {
        try {
            const result = getCookieObj("profile");
            const payloadData: IProfileData = {
                ...result,
                ...payload,
            };
            const response = await setCookieObj("profile", payloadData);
            await sleep(200)
            return response;
        } catch (error) {
            console.error("error", error);
            throw error;
        }
    };
};
