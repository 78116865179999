import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import InputField from "../components/input-field/InputField";
import InputText from "../components/input-field/InputText";
import { showInvoice } from "../utils/functions";
import { ICustomer, IProduct, ProductEnum } from "../utils/types";
import Layout from "./Layout";

const CreateInvoice = () => {
    const [customer, setCustomer] = useState<ICustomer>({});
    const [products, setProducts] = useState<IProduct[]>([]);
    useEffect(() => {
        setProducts([...products, { name: "", amount: 0, quantity: null }]);
    }, []);
    const handleSetCustomer = (e: any) => {
        let key = e.target.name;
        let val = e.target.value;
        setCustomer({ ...customer, [key]: val });
    };
    const handleChangeProduct = (e: any, index: number) => {
        let key = e.target.name;
        let val = e.target.value;
        products[index][key] = val;
        setProducts([...products]);
    };
    const handleAddProduct = () => {
        setProducts([...products, { name: "", amount: 0, quantity: null }]);
    };
    const getTotalAmount = () => {
        return products?.reduce((a, b) => a + Number(b.amount), 0);
    };
    const handleCreateInvoice = () => {
        showInvoice({customer, products});
    };
    const handleDeleteProduct = (index: number) => {
        let prouctList = [...products];
        prouctList.splice(index,1)
        setProducts(prouctList)
    }
    return (
        <Layout>
            <div className="">
                <div className="">
                    <h3 className="text-muted container">Bill To</h3>
                    <div className="card container py-2">
                        <div className="">
                            <label htmlFor="name">Customer Name</label>
                            <InputField
                                type={"text"}
                                id={"name"}
                                value={customer.name || ""}
                                onChange={handleSetCustomer}
                                className={"form-control"}
                                name={"name"}
                                placeholder="Customer name"
                                inputMode={"text"}
                            />
                        </div>
                        <div className="">
                            <label htmlFor="phone">Customer Phone</label>
                            <InputField
                                type={"text"}
                                id={"phone"}
                                value={customer.phone || ""}
                                onChange={handleSetCustomer}
                                className={"form-control"}
                                name={"phone"}
                                placeholder="XXXXXXXXXX"
                                inputMode={"numberic"}
                            />
                        </div>
                        <div className="">
                            <label htmlFor="email">Customer Email</label>
                            <InputField
                                type={"email"}
                                id={"email"}
                                value={customer.email || ""}
                                onChange={handleSetCustomer}
                                className={"form-control"}
                                name={"email"}
                                placeholder="xyz@gmail.com"
                                inputMode={"email"}
                            />
                        </div>
                    </div>
                </div>
                <div className="">
                    <br />
                    <div className="d-flex mb-3 container">
                        <h3 className="text-muted">Add Product</h3>
                        <button onClick={handleAddProduct} className="button orange bold ml-auto ">
                            + Add
                        </button>
                    </div>
                    <div className="card py-2">
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell align="left">No.</TableCell> */}
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Qty</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((row, index) => (
                                        <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            {/* <TableCell align="left">{index + 1}</TableCell> */}
                                            <TableCell component="th" scope="row">
                                                <InputText
                                                    type={"text"}
                                                    id={`name-${index}`}
                                                    onChange={(e: any) => handleChangeProduct(e, index)}
                                                    value={products[index].name}
                                                    name={ProductEnum.name}
                                                    placeholder={"Name"}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <InputText
                                                    type={"number"}
                                                    id={`quantity-${index}`}
                                                    onChange={(e: any) => handleChangeProduct(e, index)}
                                                    value={products[index].quantity}
                                                    name={ProductEnum.quantity}
                                                    placeholder={"-"}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <InputText
                                                    type={"number"}
                                                    id={`price-${index}`}
                                                    onChange={(e: any) => handleChangeProduct(e, index)}
                                                    value={products[index].price}
                                                    name={ProductEnum.price}
                                                    placeholder={"-"}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <InputText
                                                    type={"number"}
                                                    id={`amount-${index}`}
                                                    onChange={(e: any) => handleChangeProduct(e, index)}
                                                    value={products[index].amount}
                                                    name={ProductEnum.amount}
                                                    placeholder={"₹0"}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="d-flex">
                                                    {/* <IconButton>
                                                        <EditIcon className="text-info" />
                                                    </IconButton> */}
                                                    <IconButton onClick={()=>handleDeleteProduct(index)}>
                                                        <DeleteIcon className="text-danger" />
                                                    </IconButton>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="p-3 d-flex justify-content-between container">
                        <h4 className="text-muted">Total Amount</h4>
                        <h4 className="bold">₹{getTotalAmount()}</h4>
                    </div>
                    <div className="my-2 d-flex justify-content-between container">
                        <button onClick={handleAddProduct} className="button orange bold">
                            + Add More
                        </button>
                        <button onClick={handleCreateInvoice} className="button orange line bold">
                            Create Invoice
                        </button>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </Layout>
    );
};

export default CreateInvoice;
