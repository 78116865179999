import { combineReducers } from "redux";
import * as actionTypes from "./action";

export interface IState {
    language: string;
    totalCount: number;
    page: number;
    profile: actionTypes.IProfileData | null;
}

const InitialState: IState = {
    language: "en",
    page: 1,
    totalCount: 100,
    profile: null
};

const stateReducer = (State: IState = InitialState, action: IActionTypes): IState => {
    switch (action.type) {
        case actionTypes.UPDATE_UI_LANGUAGE:
            return {
                ...State,
                language: action.payload,
            };
        
        case actionTypes.PAGINATION:
            return {
                ...State,
                ...action.payload,
            };
        case actionTypes.GET_PROFILE_DATA:
            return {
                ...State,
                profile: action.payload
            }
        default:
            return State;
    }
};

export const reducers = () =>
    combineReducers<ReduxStore>({
        State: stateReducer,
    });

export interface ReduxStore {
    State: IState;
}

export type IActionTypes =
    | actionTypes.UiLanguageChangeAction
    | actionTypes.IPaginationAction
    | actionTypes.IGetProfileDataAction
