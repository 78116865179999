import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import InputField from "../components/input-field/InputField";
import { getProfileDataAction, IProfileData, updateProfileDataAction } from "../redux/action";
import { IState, ReduxStore } from "../redux/reducer";
import { startLoader, stopLoader, UploadImage } from "../utils/functions";
import Layout from "./Layout";

const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
    getProfileDataAction: getProfileDataAction,
    updateProfileDataAction: updateProfileDataAction,
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const Profile = (props: Props) => {
    const [state, setState] = useState<IProfileData>({ ...props.profile });
    const [logoFile, setLogoFile] = useState<{ file: File | null; base64: string | null }>({
        file: null,
        base64: "",
    });
    const [sign, setSign] = useState<{ file: File | null; base64: string | null }>({ file: null, base64: "" });

    useEffect(() => {
        startLoader();
        props.getProfileDataAction().then(stopLoader).catch(stopLoader);
    }, []);

    useEffect(() => {
        if (props.profile) {
            setState(props.profile);
        }
        console.log("props.profile", props);
    }, [props.profile]);

    const handleChange = (e: any) => {
        let key = e.target.name;
        let val = e.target.value;
        setState({ ...state, [key]: val });
    };
    const handleChangeAddress = (e: any) => {
        let key = e.target.name;
        let val = e.target.value;
        setState({
            ...state,
            businessAddress: {
                ...state.businessAddress,
                [key]: val,
            },
        });
    };

    const handleImgUpload = (e: any) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setLogoFile({ file, base64: String(reader.result) });
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const handleUploadSign = (e: any) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setSign({ file, base64: String(reader.result) });
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const handleUpdate = async () => {
        const payload = { ...state };
        startLoader();
        if (logoFile?.file) {
            payload.businessLogo = await UploadImage(logoFile.file);
        }
        if (sign?.file) {
            payload.signature = await UploadImage(sign.file);
        }

        props.updateProfileDataAction(payload).then(stopLoader).catch(stopLoader);
    };
    return (
        <Layout>
            <div className="py-2">
                <div className="card p-2">
                    <div className="d-flex justify-content-between ">
                        <h3 className="text-muted ">Setup Account</h3>
                        <button type="button" onClick={handleUpdate} className="button btn-sm orange">
                            Save
                        </button>
                    </div>
                    <div className="">
                        <label htmlFor="businessName">Business Name</label>
                        <InputField
                            type={"text"}
                            id={"businessName"}
                            value={state.businessName || ""}
                            onChange={handleChange}
                            className={"form-control"}
                            name={"businessName"}
                            placeholder="Business name..."
                            inputMode={"text"}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="">Business Logo</label>
                        <div className="">
                            <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                className="business-logo-input"
                                name="business-log"
                                id="business-logo"
                                onChange={handleImgUpload}
                            />
                            <label htmlFor="business-logo">
                                <img
                                    src={logoFile?.base64 || state.businessLogo || "/images/logo-placeholder.jpg"}
                                    className="logo-placeholder"
                                    alt="business logo"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="card p-2 mt-2">
                    <h3 className="text-muted">Owner Details</h3>
                    <div className="">
                        <label htmlFor="name">Owner Name</label>
                        <InputField
                            type={"text"}
                            id={"name"}
                            value={state.ownerName || ""}
                            onChange={handleChange}
                            className={"form-control"}
                            name={"ownerName"}
                            placeholder="Name"
                            inputMode={"text"}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="phone">Phone Number</label>
                        <InputField
                            type={"text"}
                            id={"phone"}
                            value={state.phone || ""}
                            onChange={handleChange}
                            className={"form-control"}
                            name={"phone"}
                            maxLength={10}
                            minLength={10}
                            placeholder="XXXXXXXXXX"
                            inputMode={"number"}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="email">Email</label>
                        <InputField
                            type={"email"}
                            id={"email"}
                            value={state.email || ""}
                            onChange={handleChange}
                            className={"form-control"}
                            name={"Email"}
                            placeholder="xyz@gmail.com"
                            inputMode={"email"}
                        />
                    </div>

                    <div className="">
                        <label htmlFor="">Owner Signature</label>
                        <div className="">
                            <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                className="business-logo-input"
                                name="signature"
                                id="signature"
                                onChange={handleUploadSign}
                            />
                            <label htmlFor="signature">
                                <img
                                    src={sign?.base64 || state.signature || "/images/logo-placeholder.jpg"}
                                    className="logo-placeholder"
                                    alt="business logo"
                                    hidden={!sign?.base64 && !state.signature}
                                />
                                <div hidden={!!sign?.base64 || !!state.signature} className="sign-placeholder text-muted">
                                    Upload Signature
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="card p-2 mt-2">
                    <h4 className="text-muted">Business Address</h4>
                    <div className="">
                        <label htmlFor="addressLine1">Address Line 1</label>
                        <InputField
                            type={"text"}
                            id={"addressLine1"}
                            value={state.businessAddress?.addressLine1 || ""}
                            onChange={handleChangeAddress}
                            className={"form-control"}
                            name={"addressLine1"}
                            placeholder="Address line 1"
                            inputMode={"text"}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="city">City</label>
                        <InputField
                            type={"text"}
                            id={"city"}
                            value={state.businessAddress?.city || ""}
                            onChange={handleChangeAddress}
                            className={"form-control"}
                            name={"city"}
                            placeholder="City"
                            inputMode={"text"}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="state">State</label>
                        <InputField
                            type={"text"}
                            id={"state"}
                            value={state.businessAddress?.state || ""}
                            onChange={handleChangeAddress}
                            className={"form-control"}
                            name={"state"}
                            placeholder="State"
                            inputMode={"text"}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="Country">Country</label>
                        <InputField
                            type={"text"}
                            id={"Country"}
                            value={state.businessAddress?.country || ""}
                            onChange={handleChangeAddress}
                            className={"form-control"}
                            name={"country"}
                            placeholder="Country"
                            inputMode={"text"}
                        />
                    </div>
                    <div className="">
                        <label htmlFor="postalCode">Postal Code</label>
                        <InputField
                            type={"text"}
                            id={"postalCode"}
                            value={state.businessAddress?.postalCode || null}
                            onChange={handleChangeAddress}
                            className={"form-control"}
                            name={"postalCode"}
                            placeholder="Postal code"
                            inputMode={"numeric"}
                        />
                    </div>
                    <hr />
                    <div className="d-flex justify-content-end px-3">
                        <button type="button" onClick={handleUpdate} className="button btn-sm orange">
                            Save
                        </button>
                    </div>
                    <br />
                </div>
            </div>
        </Layout>
    );
};

export default connector(Profile);
