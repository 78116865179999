import React from 'react'
import InvoiceCard from '../components/InvoiceCard'
import Layout from './Layout'

const Homepage = () => {
  return (
    <Layout>
        <div className="container">
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
          <InvoiceCard />
        </div>
      </Layout>
  )
}

export default Homepage