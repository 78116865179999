import React from "react";

const InvoiceCard = () => {
    return (
        <div className="card py-2 my-3">
            <div className="container">
                <div className="title">Jagan Jio</div>
                <div className="d-flex justify-content-between">
                    <span className="text-muted bold">Invoice #1</span>
                    <span className="bold">$220</span>
                </div>
                <div className="d-flex justify-content-between">
                    <span>23 Dec 2022 12:34:22 PM</span>
                    <button className="button btn-sm btn-tag">Paid</button>
                </div>
            </div>
            <hr className="mb-0" />
            <div className="container">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-link">Share on whatsapp</button>
                </div>
            </div>
        </div>
    );
};

export default InvoiceCard;
