
import React from "react";
import LabelBottomNavigation from "../components/Footer";
import Header from "../components/Header";

const Layout = (props: any) => {
    

    return (
        <div className="layout">
            <Header />
            <section className="body">{props.children}</section>
            <footer className="box-shadow">
                <LabelBottomNavigation />
            </footer>
        </div>
    );
};

export default Layout;
