import { Logout, Settings } from "@mui/icons-material";
import { Avatar, Divider } from "@mui/material";
import Popover from "@mui/material/Popover";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ProfileAvatar from "../components/ProfileAvatar";
import { ReduxStore } from "../redux/reducer";
import { clearSession } from "../utils/cookie";
import { startLoader, stopLoader } from "../utils/functions";

const Header = () => {
    const profile = useSelector((State: ReduxStore) => State.State.profile);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickProfile = () => {
        history.push("/profile");
    };
    const handleLogout = () => {
        startLoader();
        clearSession().then(() => {
            history.push("/login");
            stopLoader();
        });
    };
    return (
        <header className="d-flex container align-items-center justify-content-between">
            <h3 className="bold m-0">Invoice Generator</h3>
            <div className="profile-icon d-flex align-items-center">
                <ProfileAvatar
                    onClick={handleClick}
                    src_text={profile?.businessName || "P"}
                    src={profile?.businessLogo}
                    className={profile?.businessLogo ? "" : `circular-icon`}
                    id={"Profile"}
                    height={"40px"}
                    width={"40px"}
                />
                <Popover
                    id={!!anchorEl ? "profile-popper" : undefined}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <ul className="profile-popup p-0">
                        <li className="p-3">
                            <div onClick={handleClickProfile} className="d-flex align-items-center flex-column m-auto">
                                <ProfileAvatar
                                    onClick={handleClick}
                                    src_text={profile?.businessName || "P"}
                                    src={profile?.businessLogo}
                                    className="circular-icon p-2"
                                    id={"Profile"}
                                    height={"50px"}
                                    width={"50px"}
                                />

                                <div className="">My account</div>
                            </div>
                        </li>
                        <Divider />
                        <li>
                            <Settings className="mr-2" fontSize="small" />
                            Settings
                        </li>
                        <li onClick={handleLogout}>
                            <Logout className="mr-2" fontSize="small" />
                            Logout
                        </li>
                    </ul>
                </Popover>
            </div>
        </header>
    );
};

export default Header;
